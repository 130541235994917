var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible
    ? _c("div", { staticClass: "equipment" }, [
        _c("div", { staticClass: "equipment-main" }, [
          _c("section", [
            _c("img", {
              staticClass: "title",
              attrs: { src: require("@/assets/map/detail-title.png"), alt: "" },
            }),
            _c("i", {
              staticClass: "el-icon-circle-close icon",
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            }),
          ]),
          _vm._m(0),
          _c(
            "section",
            { staticClass: "equipment-main-table" },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.tableData, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "trackerName",
                      "show-overflow-tooltip": "",
                      label: "设备名称",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "model", label: "型号" },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "imei", label: "设备卡号" },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "设备状态" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("getStauts")(scope.row.validStatus)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2870659332
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "show-overflow-tooltip": "",
                      prop: "formattedAddress",
                      label: "地址",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "设备位置" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.formattedAddress
                                ? _c(
                                    "p",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toPosition(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-location-information",
                                      }),
                                      _c("span", [_vm._v("查看位置")]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      452515875
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "equipment-main-back" },
            [
              _c(
                "span",
                {
                  staticClass: "toBack mouseStyle",
                  on: { click: _vm.backDetail },
                },
                [_vm._v("返回合同信息")]
              ),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  total: _vm.total,
                  "page-size": 6,
                },
                on: { "current-change": _vm.changePage },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "equipment-main-title" }, [
      _c("div", { staticClass: "line-left" }),
      _c("span", [_vm._v("设备信息")]),
      _c("div", { staticClass: "line-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }