<template>
  <div class="equipment" v-if="dialogVisible">
    <div class="equipment-main">
      <section>
        <img class="title" src="@/assets/map/detail-title.png" alt="" />
        <i class="el-icon-circle-close icon" @click="dialogVisible = false"></i>
      </section>
      <section class="equipment-main-title">
        <div class="line-left"></div>
        <span>设备信息</span>
        <div class="line-right"></div>
      </section>
      <section class="equipment-main-table">
        <el-table :data="tableData" border>
          <el-table-column align="center" prop="trackerName" show-overflow-tooltip label="设备名称"> </el-table-column>
          <el-table-column align="center" prop="model" label="型号"> </el-table-column>
          <el-table-column align="center" prop="imei" label="设备卡号"> </el-table-column>
          <el-table-column align="center" label="设备状态">
            <template slot-scope="scope">
              {{ scope.row.validStatus | getStauts }}
            </template>
          </el-table-column>
          <el-table-column align="center" show-overflow-tooltip prop="formattedAddress" label="地址"> </el-table-column>
          <el-table-column align="center" label="设备位置">
            <template slot-scope="scope">
              <p v-if="scope.row.formattedAddress" @click="toPosition(scope.row)" style="cursor: pointer">
                <i class="el-icon-location-information"></i>
                <span>查看位置</span>
              </p>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <section class="equipment-main-back">
        <span class="toBack mouseStyle" @click="backDetail">返回合同信息</span>
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="6" @current-change="changePage" />
      </section>
    </div>
  </div>
</template>
<script>
import {getAllTrackerByUserId} from '../api';
export default {
  data() {
    return {
      total: 0,
      pageNum: 1,
      ContractId: '',
      dialogVisible: false,
      tableData: []
    };
  },
  watch: {
    dialogVisible: function (val) {
      if (val) {
        this.getdetail();
      }
    }
  },
  filters: {
    getStauts(val) {
      switch (val) {
        case 'NOTACTIVE':
          return '未激活';
        case 'NORMAL':
          return '已激活';
        case 'INVALID':
          return '已到期';
      }
    }
  },
  methods: {
    toPosition(item) {
      this.$store.commit('changeGeocodeData', [item.longitude, item.latitude]);
      this.dialogVisible = false;
    },
    changePage(num) {
      this.pageNum = num;
      this.getdetail();
    },
    async getdetail() {
      let res = await getAllTrackerByUserId({contractId: this.ContractId, pageNum: this.pageNum, pageSize: 6});
      if (res.errno === 0) {
        this.tableData = res.data.list;
        this.total = res.data.total;
      }
    },
    backDetail() {
      this.$emit('backDetail');
    }
  }
};
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.equipment {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  background: rgba(1, 3, 9, 0.7);
  font-size: 15px;
  &-main {
    padding: 0 72px;
    position: absolute;
    top: calc(50% - 370px);
    left: calc(50% - 531px);
    background: url('../../../assets/map/detail.png') no-repeat center/100%;
    z-index: 98;
    width: 1062px;
    height: 740px;
    .title {
      width: 244px;
      height: 50px;
      display: block;
      margin: -18px auto 0;
    }
    .icon {
      font-size: 36px;
      color: #34defc;
      position: absolute;
      top: -18px;
      right: 0;
    }
    &-title {
      height: 20px;
      font-size: 18px;
      color: #06d5f5;
      margin: 23px 0;
      display: flex;
      align-items: center;
      .line-left,
      .line-right {
        height: 2px;
        width: 420px;
        background-image: linear-gradient(to right, rgba(6, 172, 212, 1), rgba(6, 172, 212, 0));
      }
      .line-right {
        background-image: linear-gradient(to left, rgba(6, 172, 212, 1), rgba(6, 172, 212, 0));
      }
    }
    &-table {
      & ::v-deep .el-table {
        background-color: transparent;
      }
      & ::v-deep tr {
        background-color: transparent;
      }
      & ::v-deep th {
        color: #fff;
        background-color: rgba(0, 156, 255, 0.3);
      }
      & ::v-deep td {
        color: #06d5f5;
        background-color: rgba(0, 156, 255, 0.1);
      }
      & ::v-deep .el-table--border .el-table__cell {
        border-color: RGB(4, 36, 103);
        border-right: 1px solid #0454a6;
        border-bottom: 1px solid #093477;
      }
      & ::v-deep .el-table--border {
        border-color: transparent;
      }
      .el-table--border::after,
      .el-table--group::after {
        background-color: RGB(4, 36, 103);
      }
      .el-table--border::before,
      .el-table--group::before {
        background-color: RGB(4, 36, 103);
      }
      & ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
        background-color: RGB(4, 36, 103);
      }
    }
    &-back {
      padding: 0 72px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 70px;
      left: 0;
      & ::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #00ebff;
      }
      & ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #00ebff;
        color: #fff;
      }
      & ::v-deep .el-pagination.is-background .btn-next,
      .el-pagination.is-background .btn-prev,
      .el-pagination.is-background .el-pager li {
        background-color: #999eb9;
      }
      .toBack {
        width: 120px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        background-color: #01428b;
        color: #fff;
      }
    }
  }
}
</style>
